<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-24 lg:px-8 mt-5"
      >
        <div class="w-full">
          <div class="w-full">
            <div
              class="w-full p-px bg-gradient-to-r from-blue-500 to-green-500 rounded-3xl shadow-xl"
            >
              <div
                class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl bg-gray-900"
              >
                <div class="text-2xl text-yellow-500">AFFILIATE</div>
                <div class="text-sm text-gray-300">
                  COMMISSION READY EVERYDAY $1,000.00 JUST A REFERRAL AWAY
                </div>
                <div class="text-sm mt-3 text-yellow-500">MY REFERRAL CODE</div>

                <div class="text-sm mt-3 bg-gray-500">
                  <div class="flex flex-col justify-center p-2">
                    53cl4d05940
                  </div>
                </div>

                <div class="text-sm mt-3 text-yellow-500">MY REFERRAL LINK</div>

                <div class="text-sm mt-3 bg-gray-500">
                  <div class="flex flex-col justify-center p-2">
                    https://bc.fun/i-7wjo1g9o-n/
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="grid grid-cols-1 gap-1 sm:grid-cols-1 lg:grid-cols-2 md:-mt-10"
        >
          <div class="w-full p-2">
            <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
              <div
                class="w-full p-px bg-gradient-to-r from-purple-700 to-red-200 rounded-3xl shadow-xl"
              >
                <div
                  class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl bg-gray-900"
                >
                  <div class="flex flex-col items-center">
                    <img src="@/assets/svg/printing.svg" alt="" class="h-40" />

                    <div
                      class="text-2xl text-purple-300 mt-4 flex flex-col items-center"
                    >
                      <span class="text-yellow-500"> EXTRA<br /> </span> USD
                      REWARDS
                    </div>
                    <a href="#" class="text-purple-300">
                      USD Rewards Rules >>
                    </a>
                  </div>

                  <br />

                  <div class="text-sm mt-3 bg-gray-500">
                    <div class="flex flex-col items-center justify-center p-2">
                      <div class="text-3xl text-purple-700">$0.00</div>
                      <div class="text-lg">Newly Available Rewards</div>
                    </div>
                  </div>

                  <br />

                  <div class="flex flex-col items-center mt-3">
                    <p href="#" class="text-gray-300">
                      <i class="fas fa-users" style="font-size: 18px"></i>
                      All Friends <a class="text-yellow-500">0</a>
                    </p>
                  </div>

                  <div class="flex flex-col items-center mt-5">
                    <img
                      src="https://bc.fun/assets/commission.62eb3f82.png"
                      alt=""
                      class="h-20"
                    />
                    <p class="text-gray-300">
                      No info yet Invite friends to join you now!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full p-2">
            <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
              <div
                class="w-full p-px bg-gradient-to-r from-red-200 to-purple-700 rounded-3xl shadow-xl"
              >
                <div
                  class="flex flex-col h-full w-full bg-dark-900 p-6 overflow-y-auto border-none rounded-3xl bg-gray-900"
                >
                  <div class="flex flex-col items-center">
                    <img src="@/assets/svg/btc.svg" alt="" class="h-40" />

                    <div
                      class="text-2xl text-purple-300 mt-4 flex flex-col items-center"
                    >
                      <span class="text-yellow-500">MY</span> COMMISSION REWARDS
                    </div>
                    <a href="#" class="text-purple-300">
                      Commission Reward Rules >>
                    </a>
                  </div>

                  <br />

                  <div class="text-sm mt-3 bg-gray-500">
                    <div class="flex flex-col items-center justify-center p-2">
                      <div class="text-3xl text-purple-700">$0.00</div>
                      <div class="text-lg">Commission Rewards</div>
                    </div>
                  </div>

                  <br />

                  <div class="flex flex-col items-center mt-3">
                    <p href="#" class="text-gray-300">
                      Cryptocurrency Distribution Info
                    </p>
                  </div>

                  <div class="flex flex-col items-center mt-5">
                    <img
                      src="https://bc.fun/assets/commission.62eb3f82.png"
                      alt=""
                      class="h-20"
                    />
                    <p class="text-gray-300">
                      No info yet Invite friends to join you now!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />

      <div
        class="flex flex-col items-center justify-start w-full text-yellow-50 p-3"
      >
        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import logingirl from "@/assets/svg/logingirl.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
export default {
  components: {
    logingirl,
    ConnectWallet,
    StickFooter,
  },
};
</script>
